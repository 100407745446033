import React from 'react'
import { Link } from 'gatsby'

import { withPrefix } from 'gatsby-link'

// Import typefaces
import 'typeface-playfair-display'
import 'typeface-fira-sans'

// import akPic from './ak-dark.svg'
import runPic from './letsrun.svg'
import { rhythm, scale } from '../utils/typography'

class Hero extends React.Component {
  render() {
    return (
      <div
        style={{
          height: '360px',
          position: 'relative',
          overflowY: 'hidden'
        }}
      >
        <video autoPlay loop
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: -1
        }}
        src={withPrefix('/video/running.mp4')} alt="run forrest run!" />
        <img
          src={runPic}
          alt={`Let's Run The Race Together!`}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
        <p style={{ fontStyle: 'italic', textAlign: 'center' }}>
          Ayo kita berlari bersama untuk menang!
          {/*(1 Kor 19)*/}
        </p>
      </div>
    )
  }
}

export default Hero
{/*<div className="about-anton">*/}
                {/*<p>Di samping kerja dan keluarga, seorang Anton menyisihkan sebagian dari hidupnya untuk mengabdi Gereja. Bidang yang dia tekuni selama ini terutama adalah katekese dan implementasinya dalam dunia anak muda.</p>*/}
              // </div>