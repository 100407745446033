import React from 'react'

// Import typefaces
import 'typeface-fira-sans'
import 'typeface-playfair-display'

import profilePic from './self.jpg'
import { rhythm } from '../utils/typography'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2.5),
          alignItems: 'center',
        }}
      >
        <img
          src={profilePic}
          alt={`Anton Kusnadi`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
          }}
        />
        <p style={{ marginBottom: 0 }} >
          Sapa Anton di <a style={{color: "#000", fontWeight: 700}} href="mailto:hello@antonkusnadi.com">
             hello@antonkusnadi.com
          </a>
        </p>
      </div>
    )
  }
}

export default Bio
