import React from 'react'
import { Link } from 'gatsby'

import Nav from '../components/Nav'
import Hero from '../components/Hero'

import { rhythm, scale } from '../utils/typography'
import { withPrefix } from 'gatsby-link'
import runPic from './letsrun.svg'

// import '../styles/anton.styl'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

      if (location.pathname === rootPath) {
        header = (
          <header className="header">
            <Nav />
          </header>
        )
      } else {
        header = (
          <header className="header">
            <Nav />
          </header>
        )
      }
    return (
      <div className="webpage">
        {header}
        <Hero />
        <div
          className="mainpage"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: "80%",
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            backgroundColor: "#fff"
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

export default Template


